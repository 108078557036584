import { faq } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      submitted: false,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      products: [],
      currentPage: 1,
      activeTab: "all",
      params: "",
      key: 0,
      lead_id: null,
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "doctor_profile_lead_id",
          label: "Lead ID",
          class: "c_touchpt_header",
        },
        {
          key: "question",
        },
        {
          key: "answer",
        },
        {
          key: "is_active",
          label: "Status",
        },
        {
          key: "edit",
        },
        {
          key: "delete",
        },
      ],
      form: {
        id: "",
        doctor_profile_lead_id: this.$route.params.lead_id,
        question: "",
        answer: "",
        is_active: false,
      },
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async fetchData(txt) {
      this.$store.commit("loader/updateStatus", true);
      console.log(txt);
      const url = faq.getFaq;
      try {
        const res = await this.getRequest(`${url}/${this.lead_id}`);
        if (res) {
          this.tableData = res?.response;
        }
        this.$store.commit("loader/updateStatus", false);
      } catch (err) {
        this.$store.commit("toast/updateStatus"),
          {
            status: true,
            icon: "error",
            title: "somthing went wrong",
          };
        this.$store.commit("loader/updateStatus", false);
      }
    },

    async submitData() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please Fill The Required Fields",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);

        let dataAppend = {};
        for (var key in this.form) {
          if (key == "id") continue;
          dataAppend[key] = this.form[key];
        }

        if (this.$route.name == "edit-faq") {
          dataAppend["_method"] = "put";
        }
        let url = `${faq.getFaq}`;
        if (this.$route.name == "edit-faq") {
          url += "/" + this.form.id;
        }

        const res = await this.postRequest(url, dataAppend);
        if (res.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: res?.message,
          });
          this.$router.push(`/memebr-faq/${this.lead_id}`);
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async updateStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        const url = `${faq.updateFaqStatus}/${id}`;
        const data = await this.postRequest(url, {
          is_active: !this.tableData.data[index].is_active,
        });
        console.log(data);
        if (data.status) {
          this.tableData.data[index].is_active =
            !this.tableData.data[index].is_active;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: "Status updated successfully",
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async deleteFaq(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id === id);
        let url = `${faq.getFaq}/${id}`;
        const data = await this.postRequest(url, {
          _method: "DELETE",
        });
        if (data.status) {
          this.tableData.data.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async fetchFaq(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const url = `${faq.getOneFaq}/${id}`;
        const res = await this.getRequest(url);
        if (res.status) {
          if (res.response?.id !== null && res.response?.id !== "") {
            this.form.id = res.response.id;
          }
          if (
            res.response?.doctor_profile_lead_id !== null &&
            res.response?.doctor_profile_lead_id !== ""
          ) {
            this.form.doctor_profile_lead_id =
              res.response.doctor_profile_lead_id;
          }
          if (
            res.response?.question !== null &&
            res.response?.question !== ""
          ) {
            this.form.question = res.response.question;
          }
          if (res.response?.answer !== null && res.response?.answer !== "") {
            this.form.answer = res.response.answer;
          }
          if (
            res.response?.is_active !== null &&
            res.response?.is_active !== ""
          ) {
            if (res.response.is_active == 1) this.form.is_active = true;
            else this.form.is_active = false;
          }
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },

  watch: {
    // currentPage: {
    //   handler: function (v) {
    //     if (v != "" && v != null) {
    //       this.fetchData(this.activeTab);
    //       this.pageNo = this.currentPage;
    //     }
    //   },
    // },
  },
  created() {
    this.lead_id = this.$route.params.lead_id;
    if (this.$route.name == "add-faq" || this.$route.name == "edit-faq") {
      if (this.$route.name == "edit-faq") {
        this.fetchFaq(this.$route.params.id);
      }
    } else {
      this.fetchData();
    }
  },
};
